import React from 'react'
import '../styles/Navbar.css';
import '../styles/Mediaquerry.css';

import '../javascript/nav.js'

import logo from "../assets/images/logo.png"
import { Link } from 'react-router-dom';

console.log("hello")

export default function Navbar() {
  return (
    <>
    <navbar>
        <div id='top-bar'>
            <div> <a className='link-btn' href='mailto:email@example.com'>Info@suryagroup.us</a></div>
            <div><a  className='link-btn' href='https://wa.me/9634377090'>+91 9634377090</a></div>
        </div>

        <header>
            <ul className='nav-ele'>
                <li><Link className=' nav-link link-btn active' to="/">Home </Link></li>
                <li><Link className=' nav-link link-btn ' to="/about">About</Link></li>
                <li  id='drop-access'><div className='link-btn' style={{lineHeight:"100px"}}>Services </div>
                    <div id='visiblity' className='dropdown-bar'><ul>
                        <li className='dropdown-btn'><Link className='link-btn ' to="/general-contractor">General Contractor</Link></li>
                        <li id='drop-access-inner' className='dropdown-btn'><Link className='link-btn ' to="/">Sub-Contractor</Link>
                            <div className='dropdown-inner' id='dropdown-inner-item' >
                                <div >
                                <div className='left-drop'>
                                        <div>
                                            <h6>Finishes</h6>  
                                            <div>
                                                <Link className="link-btn " to = "/services/Flooring Estimation">Flooring Estimation</Link>
                                                <Link className="link-btn " to = "/services/Drywall Estimation">Drywall Estimation</Link>
                                                <Link className="link-btn " to = "/services/Painting Estimation">Painting Estimation</Link>
                                                <Link className="link-btn " to = "/services/Siding Estimation">Siding Estimation</Link>
                                            </div>                                      
                                        </div>
                                    </div>

                                    <div>
                                        <div className='middle-drop'>
                                            <h6>Structural & Envelope</h6>                                        
                                            <div>
                                                    <Link className="link-btn" to = "/services/Lumber Estimation">Lumber Estimation</Link>
                                                    <Link className="link-btn" to = "/services/Concrete Estimation">Concrete Estimation</Link>
                                                    <Link className="link-btn" to = "/services/Masonry Estimation">Masonry Estimation</Link>
                                                    <Link className="link-btn" to = "/services/Roofing Estimation">Roofing Estimation</Link>
                                                    <Link className="link-btn" to = "/services/Insulation & Moisture Protection">Insulation & Moisture Protection</Link>
                                                    <Link className="link-btn" to = "/services/Demolition Estimation">Demolition Estimation</Link>
                                                </div>
                                        </div>
                                    </div>
                                    
                                    <div className='right-drop'>
                                        <div>
                                            <h6>Mechanical, Electrical & Specialty</h6> 
                                            <div>
                                                <Link className="link-btn" to = "/services/Electrical Estimation">Electrical Estimation</Link>
                                                <Link className="link-btn" to = "/services/MEP Estimation">MEP Estimation</Link>
                                                <Link className="link-btn" to = "/services/Openings & Hardware">Openings & Hardware</Link>
                                            </div>                                       
                                        </div>
                                    </div>
                                </div>
                                {/* <li className='dropdown-btn' ><Link className=' nav-link link-btn ' to="#!">Lumber Takeoff</Link></li> */}
                            </div>
                        </li>
                        </ul></div>
                </li>
                <div id='logo'><img src={logo}  alt='suryagroup.us'/></div>
                <li><Link className=' nav-link link-btn ' to="/blog">Blog</Link></li>
                <li><Link className=' nav-link link-btn ' to="/faq">FAQ</Link></li>
                <li><Link className=' nav-link link-btn ' to="/contact-us">Contact Us</Link></li>

                <div id='sign-btn'>
                    <Link className='cmn-btn cmn-btn-dark' to= "/login">Sign In</Link>
                </div>
            </ul>
            
        </header>
    </navbar>


{/*     
    <div>
        <div>
            <div>Estimates@suryagroup.tech</div>
        </div>
    </div>
    <header>
        <navbar>
            <a href="/"><img src="../images/newlogo.png" alt=""/></a>
            <input type="checkbox" id="click"/>
            <label onclick="blur_body()" for="click">
                <div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </label>
            

            <nav>
                <ul>
                    <li><a href="#"><i className="fa-solid fa-house"></i> Home</a> </li>
                    <li><a href="/about">About Us</a> </li>
                    <li><a href="#">Services <i className="fas fa-caret-down"></i></a>
                        <div>
                            <ul>
                                <li><a href="/estimate/general_contractor"> General Contractor </a></li>
                                <li><a href="#"> Sub-Contractor <i className="fas fa-caret-right"></i></a></li>
                                <div>
                                    <ul>
                                        <li><a href="/service/lumber">Lumber Framing</a></li>
                                        <li><a href="/service/roofing">Roofing </a></li>
                                        <li><a href="/service/drywall">Drywall </a></li>
                                        <li><a href="/service/metal_framing">Metal Framing</a></li>
                                        <li><a href="/service/insulation">Insulation</a></li>
                                        <li><a href="/service/earthwork">Earthwork </a></li>
                                        <li><a href="/service/MEP">MEP </a></li>
                                        <li><a href="/service/masonry">Masonry</a></li>
                                        <li><a href="/service/concret">Concrete </a></li>
                                        <li><a href="/service/siding_&_trims">Siding & Trims </a></li>
                                        <li><a href="/service/painting">Painting</a></li>
                                        <li><a href="/service/doors_and_windows">Openings</a></li>
                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </li>
                    <li><a href="/example">Examples</a> </li>
                    <li><a href="/FAQ">FAQ</a> </li>
                    <li><a href="/contact">Contact Us</a> </li>
                </ul>
            </nav>
        </navbar>
    </header> */}
</>

  )
}







